import { Link, graphql } from 'gatsby';

import { FormattedMessage } from 'react-intl';
import Layout from '../components/layouts';
import Navbar from '../components/Navbar';
import React from 'react';
import SEO from '../components/SEO';
import { blogCategories } from '../constants/blog';
import moment from 'moment';
import styles from '../components/Blog/BlogArticle/index.module.sass';

export default function Template({ data, location }) {
  const post = data.markdownRemark;
  const otherArticles = data.allMarkdownRemark.edges.map(edge => edge.node);

  return (
    <Layout location={location}>
      <SEO title={post.frontmatter.title} image={post.frontmatter.coverImage} article />
      <section className="blog">
        <div
          className="blogHeader articleHeader"
          style={{
            backgroundImage: `url(${post.frontmatter.coverImage})`
          }}
        >
          <div className="container blogOuter">
            <Navbar />
            <div>
              <h1 className="blogTitle">{post.frontmatter.title}</h1>
              <h4 className="blogSubtitle">{moment(post.frontmatter.date).format('ll')}</h4>
            </div>
            <div />
          </div>
        </div>
        <div className={styles.blogContent}>
          <div className={styles.blogContentOuter}>
            <article className="content" dangerouslySetInnerHTML={{ __html: post.html }} />
            <div className={styles.blogSocials}>
              <FormattedMessage id="article.share" />
              <ul>
                <li>
                  <div className="fb-share-button" data-layout="button"
                    data-href={'https://herosnatch.com' + post.frontmatter.path}>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <aside>
            <div className={styles.asideCol}>
              <b>
                <FormattedMessage id="article.category" />:
              </b>
              <ul>
                {blogCategories.map(category => (
                  <li key={category}>
                    <Link
                      to="/blog"
                      state={{
                        category
                      }}
                    >
                      <FormattedMessage id={`blog.${category}`} />
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className={styles.asideCol}>
              <b>
                <FormattedMessage id="article.articles" />:
              </b>
              <ul>
                {otherArticles
                  .filter(article => post.id !== article.id)
                  .slice(0, 4)
                  .map(article => (
                    <li key={article.id}>
                      <Link to={article.frontmatter.path}>{article.frontmatter.title}</Link>
                    </li>
                  ))}
              </ul>
            </div>
          </aside>
        </div>
        <div className="blogFooter">
          <div className="blogFooterInner">
            <Navbar />
          </div>
        </div>
      </section>
    </Layout>
  );
}

export const postQuery = graphql`
  query BlogPostByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      id
      html
      frontmatter {
        path
        title
        author
        date
        coverImage
      }
    }
    allMarkdownRemark {
      edges {
        node {
          id
          frontmatter {
            path
            title
          }
        }
      }
    }
  }
`;
